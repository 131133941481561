<template>
  <div class="c-app flex-row align-items-center">
    <CContainer class="col-md-7">
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path" />
            </transition>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <LoginRight />
  </div>
</template>

<script>
import LoginRight from "@/components/LoginRight";

export default {
  name: "AccountContainer",
  components: { LoginRight },
};
</script>
<style>
.c-app {
  background: #f8faff;
}
</style>
