<template>
  <CContainer class="col-md-5 login-right">
    <CRow class="login-right__content">
      <CCol class="login-right__text">
        <h3 class="login-right__title">DataMorph</h3>
        {{ $t("auth.right") }}
        <!-- <br />
        <br /> -->
        <!-- {{ $t("Future is now") }}. -->
      </CCol>
    </CRow>
    <!-- <CRow class="h-50">
      <CCol class="login-right__text">
        <h3>DataMorph</h3>
        <h4 class="mt-3">
          {{ $t("auth.right") }}
          <br />
          <br />
          {{ $t("Future is now") }}.
        </h4>
      </CCol>
    </CRow> -->
  </CContainer>
</template>

<script>
export default {
  name: "LoginRight",
};
</script>

<style scoped lang="scss">
.login-right {
  height: 100vh;
  background: linear-gradient(
    358.14deg,
    #38afe1 0%,
    #3363c0 42.58%,
    #1b3462 99.99%
  );
  box-shadow: 2px 2px 10px rgba(12, 32, 68, 0.05);
  border-radius: 30px 0px 0px 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-right__title {
  font-family: "Lato";
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 30px;
}

.login-right__text {
  padding: 25px 40px;
  font-family: "Lato";
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  max-width: 496px;
}

@media only screen and (max-width: 767px) {
  .login-right {
    display: none;
  }
}
</style>
